.portfolio {
    .list {
        position: relative;

        .main-title {
            padding-right: 20px;
            padding-left: 20px;
        }

        .detail {
            position: relative;
            padding-bottom: 80px;
            @include media-queries($from: md) {
                padding: 60px 0 110px;
            }
            @include media-queries($from: md) {
                padding: 60px 0 100px;
            }
        }

        .swiper-container {
            padding-bottom: 30px;
            vertical-align: top;

            @include media-queries($from: md) {
                padding: 40px 0 60px;
            }

            @include media-queries($from: xl) {
                padding: 40px 0 70px;
            }
        }

        .swiper-slide {
            position: relative;
            width: 300px;
            height: 400px;
            padding: 0 10px;
            @include media-queries($from: md) {
                width: 400px;
                height: 536px;

                &:nth-child(odd) {
                    width: 320px;
                    height: 456px;
                    padding: 40px 10px;
                }
            }
        }

        .swiper-pagination {
            @include media-queries($from: md) {
                display: none;
                //visibility: hidden;
            }
        }

        .swiper-buttons {
            //position: absolute;
            //top: -65px;
            //right: 10px;
            //left: calc(50% + 475px);
            //width: 110px;
            //height: 24px;

            .button-prev,
            .button-next {
                position: absolute;
                top: 0;
                z-index: 10;
                width: 100px;
                height: 100%;
                opacity: 0;
                transition: opacity .3s ease;

                &:hover {
                    opacity: 1;
                }

                &:focus {
                    outline: 0;
                }
            }

            .button-prev {
                left: 40px;

                @include media-queries($from: xl) {
                    left: calc(50% - 600px);
                }
            }

            .button-next {
                right: 40px;

                @include media-queries($from: xl) {
                    right: calc(50% - 600px);
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                top: calc(50% - 28px);
                width: 40px;
                height: 40px;

                &:focus {
                    outline: 0;
                }

                &:before,
                &:after {
                    top: inherit;
                    bottom: 0;
                    width: 40px;
                    height: 2px;
                    background: #000;
                    transform: rotate(-90deg);
                    box-shadow: none;
                }

                &:before {
                    top: 20px;
                    left: -20px;
                }

                &:after {
                    top: 40px;
                    left: 0;
                    transform: rotate(0);
                }
            }

            .swiper-button-prev {
                left: 0;
                transform: rotate(45deg);

                @include media-queries($from: xl) {
                    left: 32px;
                }
            }

            .swiper-button-next {
                right: 0;
                transform: rotate(-135deg);

                @include media-queries($from: xl) {
                    right: 31px;
                }
            }

            @include media-queries($to: md) {
                display: none;
                //visibility: hidden;
            }

            @include media-queries($from: xl) {
                //right: auto;
                //left: calc(50% + 475px);
            }
        }

        .content {
            position: relative;
            height: 100%;

            @include media-queries($to: md) {
                overflow: hidden;
            }
        }

        figure {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: {
                repeat: no-repeat;
                position: center;
                size: cover;
            };
            transform-origin: center;
            //transition: .3s ease;
        }

        h3 {
            position: relative;
            padding: 30px 20px 0 30px;
            font-weight: $font-weight-light;
            font-size: 14px;

            strong {
                display: block;
                font-weight: $font-weight-extrabold;
                font-size: 24px;
                font-family: $font-language-en;
                line-height: 1;
                letter-spacing: 0;
            }
        }

        dl {
            position: absolute;
            bottom: 10px;
            left: 0;
            width: 100%;
            padding: 50px 20px 0 40px;
            box-sizing: border-box;

            @include media-queries($from: md) {
                //position: relative;
                top: 160px;
                bottom: inherit;
                left: 0;
                padding: 0 0 0 10px;
            }
        }

        dl > div {
            opacity: 0;
            transform: translateY(20px);
        }

        dt {
            display: inline-block;
            position: relative;
            font-weight: $font-weight-bold;
            font-size: 16px;
            font-family: $font-language-en;
            line-height: 24px;
            letter-spacing: 0;
            vertical-align: top;

            &:before {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: #000;
                content: "";
            }

            @include media-queries($from: md) {
                padding: 8px 0;
                font-size: 18px;
            }
        }

        dd {
            padding: 5px 0 20px;
            font-family: $font-language-en;
            font-weight: $font-weight-medium;
            font-size: 12px;
            box-sizing: border-box;
            @include clearfix;
            @include media-queries($from: md) {
                min-height: 100px;
                margin-top: -42px;
                padding: 10px 0 70px 150px;
                font-size: 17px;
            }

            div {
                float: left;
                font-family: $font-language-en;

                @include media-queries($from: md) {
                    width: 50%;
                }


                @include media-queries($to: md) {
                    + div {
                        margin-left: 15px;
                    }
                }
            }

            strong {
                display: block;
                font-weight: $font-weight-bold;
                font-size: 25px;
                font-family: $font-language-en;
                @include media-queries($from: md) {
                    font-size: 36px;
                }
            }

            span {
                font-weight: $font-weight-regular;
                font-size: 18px;
                @include media-queries($from: md) {
                    font-size: 32px;
                }
            }
        }

        dd + dt {
            clear: left;
        }

        .swiper-slide-active {
            z-index: 10;

            .content {
                box-shadow: 15px 15px 15px rgba(0,0,0,.1);
            }

            @include media-queries($from: md) {
                .content {
                    //width: 500px;
                    //height: 670px;
                    width: 400px;
                    height: 536px;
                    box-shadow: none;
                }

                &:nth-child(odd) {
                    .content {
                        transform: translate(-50px, -50px);
                    }
                }
            }

            figure {
                transform: scale((557 / 500), (745 / 670));
                transition: transform .4s ease, background-image .4s ease;

                @include media-queries($from: md) {
                    box-shadow: 15px 15px 15px rgba(0,0,0,.1);
                }

                @include media-queries($from: xl) {
                    box-shadow: 20px 20px 20px rgba(0,0,0,.1);
                }
            }

            &.beauty figure {
                @include media-queries($from: md) {
                    background-image: url("/assets/images/bg_portfolio_1_active.jpg");
                }
            }

            &.home-appliances figure {
                @include media-queries($from: md) {
                    background-image: url("/assets/images/bg_portfolio_2_active.jpg");
                }
            }

            &.book-store figure {
                @include media-queries($from: md) {
                    background-image: url("/assets/images/bg_portfolio_3_active.jpg");
                }
            }

            &.health-food figure {
                @include media-queries($from: md) {
                    background-image: url("/assets/images/bg_portfolio_4_active.jpg");
                }
            }

            &.financial-corporation figure {
                @include media-queries($from: md) {
                    background-image: url("/assets/images/bg_portfolio_5_active.jpg");
                }
            }

            h3 {
                //transition: transform .4s ease .3s;
                @include media-queries($from: md) {
                    left: -30px;
                    font-size: 20px;
                    transform: translateY(-15px);

                    strong {
                        font-size: 40px;
                    }
                }
            }

            dl > div {
                opacity: 1;
                transform: translateY(0);
                transition: .5s ease;

                @for $i from 1 through 5 {
                    &:nth-child(#{$i}) {
                        transition-delay: (.3s + $i * .1s);
                    }
                }

            }
        }

        .beauty figure {
            background-image: url("/assets/images/bg_portfolio_1_mo.jpg");

            @include media-queries($from: md) {
                background-image: url("/assets/images/bg_portfolio_1.jpg");
            }
        }

        .home-appliances figure {
            background-image: url("/assets/images/bg_portfolio_2_mo.jpg");

            @include media-queries($from: md) {
                background-image: url("/assets/images/bg_portfolio_2.jpg");
            }
        }

        .book-store figure {
            background-image: url("/assets/images/bg_portfolio_3_mo.jpg");

            @include media-queries($from: md) {
                background-image: url("/assets/images/bg_portfolio_3.jpg");
            }
        }

        .health-food figure {
            background-image: url("/assets/images/bg_portfolio_4_mo.jpg");

            @include media-queries($from: md) {
                background-image: url("/assets/images/bg_portfolio_4.jpg");
            }
        }

        .financial-corporation figure {
            background-image: url("/assets/images/bg_portfolio_5_mo.jpg");

            @include media-queries($from: md) {
                background-image: url("/assets/images/bg_portfolio_5.jpg");
            }
        }

        .detail {
            //cursor: none;
        }

        .prev_next {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 100;
            width: 100px;
            height: 100px;
            margin: -50px 0 0 -50px;
            border-radius: 50%;
            background: rgba(#000, .5);
            transition: transform .1s ease;
            pointer-events: none;

            &.on {
                display: block;
            }

            button {
                display: none;
                position: relative;
                width: 100%;
                height: 100%;
                border-radius: 0;
                //pointer-events: auto;

                &:before,
                &:after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform-origin: center;
                    content: "";
                }

                &:before {
                    width: 30px;
                    height: 2px;
                    background: #fff;
                }

                &:after {
                    width: 20px;
                    height: 20px;
                    transform: rotate(-45deg);
                }

                &.prev:after {
                    box-shadow: inset 2px 2px 0 #fff;
                }

                &.next:after {
                    box-shadow: inset -2px -2px 0 #fff;
                }

                &.on {
                    display: block;
                }

                span {
                    @include visually-hidden;
                }
            }
        }
    }

    .info {
        padding: 80px 25px;
        background: #f8f8f8;
        @include media-queries($from: md) {
            padding: 160px 0;
        }

        h3 {
            position: relative;
            padding: 4px 0;
            @include clearfix;

            &:before {
                position: absolute;
                top: 0;
                bottom: 0;
                left: (146px + 16);
                width: 2px;
                background: #000;
                content: "";

                @include media-queries($from: md) {
                    left: (230px + 29);
                }
            }

            > span {
                float: left;
            }

            .recobell {
                width: 146px;

                @include media-queries($from: md) {
                    width: 226px;
                }
            }

            .fsn {
                width: 62px;
                margin-left: 35px;

                @include media-queries($from: md) {
                    width: 100px;
                    margin-left: 60px;
                }
            }
        }

        .main-description {
            padding: 50px 0;
        }

        dl {
            position: relative;

            &:before {
                display: block;
                padding-top: percentage(870 / 650);
                content: "";
                @include media-queries($from: md) {
                    padding-top: percentage(410 / 1160);
                }
            }
        }

        dt,
        dd {
            position: absolute;
            border-radius: 50%;
            text-align: center;

            &:before {
                display: block;
                padding-top: 100%;
                content: "";
            }
        }

        dt {
            top: percentage(285 / 870);
            left: percentage(165 / 650);
            width: percentage(320 / 650);
            background: #000;
            color: #fff;
            font: 0/0 'a';
            text-align: center;
            @include media-queries($from: md) {
                top: percentage(60 / 410);
                left: percentage(430 / 1160);
                width: percentage(320 / 1160);
            }

            &:before {
                display: block;
                padding-top: 100%;
                content: "";
            }

            > span {
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                transform: translateY(-50%);
            }

            .logo {
                display: inline-block;
                width: percentage(230 / 320);
                vertical-align: top;
            }

            .logo + span {
                display: block;
                padding-top: 4px;
                font-weight: $font-weight-light;
                font-size: 10px;
                line-height: 1.1;
                letter-spacing: 0;
                @include media-queries($from: md) {
                    font-size: 16px;
                }
            }
        }

        dd {
            background: #fff;
            font-size: 12px;
            line-height: 16px;

            > span {
                position: absolute;
            }

            .logo {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .logo + span {
                left: 0;
                bottom: -24px;
                width: 100%;
                font-size: 12px;
                white-space: nowrap;
                text-align: center;
                @include media-queries($from: md) {
                    bottom: -30px;
                    font-size: 14px;
                }
            }

            @each $name, $top, $left, $width, $logoSize in (
                            (cauly, 155, 30, 135, 85),
                            (behave, 0, 220, 130, 95),
                            (nmg, 35, 430, 210, 140),
                            (inbuzz, 550, 30, 160, 120),
                            (e-motion, 660, 250, 165, 115),
                            (idd, 510, 490, 145, 75)
            ) {
                &.#{$name} {
                    top: percentage($top / 870);
                    left: percentage($left / 650);
                    width: percentage($width / 650);

                    .logo {
                        width: percentage($logoSize / $width);
                    }
                }
            }

            @include media-queries($from: md) {
                @each $name, $top, $left, $width, $logoSize in (
                                (cauly, 75, 0, 110, 70),
                                (behave, 225, 110, 115, 85),
                                (nmg, 0, 210, 210, 140),
                                (inbuzz, 260, 715, 115, 90),
                                (e-motion, 30, 860, 165, 115),
                                (idd, 225, 1015, 145, 75)
                ) {
                    &.#{$name} {
                        top: percentage($top / 420);
                        left: percentage($left / 1160);
                        width: percentage($width / 1160);

                        .logo {
                            width: percentage($logoSize / $width);
                        }
                    }
                }
            }
        }
    }
}