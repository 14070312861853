.header {
    position: fixed;
    top:0;
    left:0;
    z-index: 100;
    width: 100%;
    padding: (56px / 3) (55px / 2);
    box-sizing: border-box;

    &.on {
        button {
            > span,
            &:before,
            &:after {
                background: #fff !important;
            }

            > span {
                opacity: 0;
            }

            &:before,
            &:after {
                top: 50%;
                left: 50%;
                width: 33px;
                margin:-1px 0 0 -16px;
                transform-origin: center;
                transition: transform .3s ease;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }

        nav {
            display: block;
            width: 100%;
            height: 100%;
            visibility: visible;
            opacity: 1;

            li {
                top: 0;
                opacity: 1;
                transition: top .3s ease, opacity .3s ease;

                @each $index in 1,2,3,4,5 {
                    &:nth-child(#{$index}) {
                        transition-delay: #{0.1 * $index}s;
                    }
                }
            }
        }
    }

    &.scroll {
        background: #fff;

        h1 {
            a {
                background: url(/assets/images/h_logo_2.png) no-repeat center / contain;
            }
        }

        button {
            > span,
            &:before,
            &:after {
                background: #000;
            }
        }
    }

    h1 {
        float: left;
        width: percentage(175 / 750);
        max-width: 130px;

        a {
            display: block;
            background: url(/assets/images/h_logo.png) no-repeat center / contain;

            &:before {
                display: block;
                padding-top: percentage(32/ 175);
                content:"";
            }
        }

        span {
            @include visually-hidden;
        }
    }

    button {
        $width: 50px;
        $height: 40px;

        float: right;
        position: relative;
        z-index: 20;
        width: ($width / 2);
        height: ($width / 2);

        > span,
        &:before,
        &:after {
            position: absolute;
            left: 0;
            width: 100%;
            height: 2px;
            background: #fff;
        }

        &:before,
        &:after {
            content:"";
        }

        &:before {
            top: 0;
        }

        > span {
            top: 50%;
            margin-top: -1px;
            opacity: 1;
        }

        &:after {
            bottom: 0;
        }

        span span {
            @include visually-hidden;
        }
    }

    nav {
        position: fixed;
        top:0;
        left:0;
        width: 0;
        height: 0;
        visibility: hidden;
        opacity: 0;

        &:before {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            background: #000;
            content:"";
        }

        ul {
            overflow-y: auto;
            position: relative;
            z-index: 10;
            height: 100%;
            padding: (176px / 2) 0 (220px / 2);
            box-sizing: border-box;
        }

        @each $index, $color in (1),(2),(3),(4),(5, #1261d8) {
            .active-#{$index} li:nth-child(#{$index}) a:before {
                width: 100%;

                @if($color) {
                    background: #{$color};
                }
            }
        }

        li {
            position: relative;
            top: 30px;
            text-align: center;
            opacity: 0;
        }

        li + li {
            margin-top: 55px;
        }

        a {
            display: inline-block;
            position: relative;
            padding-bottom: 5px;

            &:before {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 2px;
                background: #fff;
                transition: width .3s .2s ease;
                content: "";
            }
        }

        span {
            display: block;
            color: #fff;
            font-weight: 600;
            font-size: 36px;
            line-height: 1;
        }

        .blue.blue {
            span {
                color: #1261d8;
            }
        }

        .bar {
            display: none;
        }
    }

    @include media-queries($from: xl) {
        padding: 30px 0;

        &.scroll {
            nav {
                span {
                    color: #000;
                }
            }

            .bar {
                background: #000;
            }
        }

        &-container {
            width: 1200px;
            margin: 0 auto;

            @include clearfix;
        }

        h1 {
            float: left;
            width: 130px;
            height: 24px;

            a {
                display: block;
                width: 100%;
                height: 100%;
                background: url(/assets/images/h_logo.png) no-repeat center / contain;

                &:before {
                    display: none;
                }
            }
        }

        button {
            display: none;
        }

        nav {
            display: block;
            float: right;
            position: relative;
            width: auto;
            height: auto;
            visibility: visible;
            opacity: 1;

            &:before {
                display: none;
            }

            ul {
                position: relative;
                overflow-y: visible;
                padding: 0;

                @include clearfix;
            }

            li {
                float: left;
                top: 0;
                opacity: 1;
            }

            li + li {
                margin: 0;
                padding: 0 0 0 67px;
            }

            a {
                position: relative;
                padding-bottom: 10px;

                &:before {
                    display: none;
                }
            }

            span {
                color: #fff;
                font-family: $font-language-en;
                font-weight: 700;
                font-size: 16px;
            }

            .bar {
                display: block;
                float: none;
                position: absolute;
                top: inherit;
                bottom:0;
                left:0;
                width: 53px;
                height: 2px;
                padding: 0;
                background: #fff;
                transition: left .3s ease-in-out, width .3s ease-in, background .3s ease-in;
                content:"";
            }

            @each $index, $width, $offsetLeft, $bgColor in (1, 58, 0), (2, 82, 125), (3, 84, 273), (4, 95, 424), (5, 78, 588, #1261d8) {
                > ul.active-#{$index} li:nth-child(#{$index}) ~ .bar,
                > ul > li:nth-child(#{$index}):hover ~ .bar {
                    left: #{$offsetLeft}px;
                    width: #{$width}px;

                    @if $bgColor {
                        background: #{$bgColor};
                    }
                }

                > ul > li:nth-child(#{$index}):hover ~ .bar {
                    left: #{$offsetLeft}px !important;
                    width: #{$width}px !important;
                }
            }
        }
    }
}