.business {
    .partner {
        margin-top: 40px;

        @include clearfix;

        li {
            float: left;
            width: percentage(1 / 3);
            margin-top: 20px;
        }

        @each $partner, $height in (naver, 33), (google, 54), (kakao, 41), (daum, 52), (criteo, 43), (nate, 36) {
            .#{$partner} figure span {
                display: block;
                height: (54px / 2);
                background: url(/assets/images/img_business_#{$partner}.png) no-repeat center / auto #{$height / 2}px;
            }
        }

        li > span {
            display: block;
            min-height: 30px;
            padding-top: 10px;
            color: #000;
            font-size: 12px;
            text-align: center;
            line-height:(24 / 22);
        }
    }

    .category {
        margin-top: 90px;

        .swiper-slide {
            .list {
                overflow: hidden;
                margin: -15px 0 0 -15px;
            }

            .list > div {
                float: left;
                position: relative;
                width: 50%;
                padding: 15px 0 0 15px;
                box-sizing: border-box;

                > div {
                    position: relative;

                    &:before {
                        display: block;
                        padding-top: 100%;
                        content: "";
                    }
                }
            }

            p {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 15px;
                box-sizing: border-box;

                &:nth-child(2n + 1) {
                    clear: both;
                }
            }
        }


        .swiper-pagination {
            position: relative;
            width: 100%;
            margin-top: 20px;
            text-align: center;
        }

        .swiper-pagination-bullet {
            width: 7px;
            height: 7px;
            margin: 0 5px;
            background: #e7e6ec;
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            background: #797979;
        }

        @each $category in search, display, local, shopping, social, mobile-app, video, network {
            .#{$category} p {
                background: url(/assets/images/img_business_#{$category}.png) no-repeat center / cover;
            }
        }

        p {
            color: #353535;
            font-family: $font-language-en;
            font-size: 12px;

            strong {
                color: #000;
                font-weight: 700;
                font-family: $font-language-ko;
                font-size: 14px;
                line-height: (61 / 24);
            }
        }
    }

    @include media-queries($from: md) {
        .partner {
            @each $partner, $height in (naver, 33), (google, 54), (kakao, 41), (daum, 52), (criteo, 43), (nate, 36) {
                .#{$partner} figure span {
                    height: (54px / 1.5);
                    background: url(/assets/images/img_business_#{$partner}.png) no-repeat center / auto #{$height / 1.5}px;
                }
            }

            li > span {
                font-size: 14px;
            }
        }

        .category {
            .swiper-wrapper {
                margin-left: -15px;
            }

            .swiper-slide {
                width: calc(50% - 7px);
                margin-left: 15px;
            }

            .swiper-pagination {
                opacity: 0;
            }

            p {
                font-size: 14px;

                strong {
                    font-size: 16px;
                }
            }
        }
    }

    @include media-queries($from: xl) {
        .text {
            font-size: 24px;
            line-height: (40 / 24);

            @include clearfix;

            strong {
                float: left;
                font-size: 44px;
                line-height: (64 / 44);
            }

            > span {
                float: right;
                margin: 0;
            }
        }

        .partner {
            margin-top: 170px;

            li {
                float: left;
                width: percentage(1 / 6);
                margin-top: 0;
            }

            @each $partner, $height in (naver, 24), (google, 39), (kakao, 30), (daum, 38), (criteo, 31), (nate, 26) {
                .#{$partner} figure span {
                    height: 44px;
                    background: url(/assets/images/img_business_#{$partner}.png) no-repeat top center / auto #{$height}px;
                }
            }

            li > span {
                font-size: 16px;
            }
        }

        .category {
            margin-top: 110px;

            .swiper-wrapper {
                margin-left: -30px;
            }

            .swiper-slide {
                width: calc(50% - 15px);
                margin-left: 30px;

                .list {
                    margin: -30px 0 0 -30px;
                }

                .list > div {
                    padding: 30px 0 0 30px;
                }
            }

            .swiper-pagination {
                opacity: 0;
            }

            p {
                font-size: 18px;

                strong {
                    font-size: 24px;
                }
            }
        }
    }
}