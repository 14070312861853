.contact-us {
    .info {
        padding-bottom: 85px;
        @include clearfix;
        @include media-queries($from: md) {
            padding-bottom: 210px;
        }

        address {
            display: block;
            margin-top: -10px;
            font-size: 14px;

            @include media-queries($from: md) {
                float: left;
                width: calc(100% - 300px);
                font-size: 18px;
            }

            span,
            a {
                padding: 10px 0;
            }

            span {
                display: block;
                padding-left: 20px;
                font-weight: $font-weight-light;
                background: url("/assets/images/icon_contact_us.png") no-repeat left center/auto 17px;
            }

            a {
                display: block;
                color: #1f1e1e;
                font-weight: $font-weight-medium;
                vertical-align: top;

                @include media-queries($from: md) {
                    display: inline-block;
                    padding-right: 10px;
                }
            }
        }

        button {
            display: block;
            width: 100%;
            height: 50px;
            margin-top: 30px;
            border-radius: 0;
            border: 0;
            background: #000;
            color: #fff;
            font-weight: $font-weight-bold;
            font-size: 18px;
            line-height: 50px;

            @include media-queries($from: md) {
                float: right;
                width: 270px;
                height: 70px;
                margin-top: 0;
                font-size: 24px;
                line-height: 70px;
            }
        }
    }

    .form {
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        height: 0;
        background: #000;
        color: #fff;
        opacity: 0;

        @at-root .open-contact-us-form {
            overflow: hidden;
        }

        @at-root .open-contact-us-form & {
            overflow-y: auto;
            height: 100%;
            opacity: 1;
            transition: .4s;

            .close {
                transform: rotate(180deg);
                transition: .6s .3s;
            }
        }

        .inner {
            max-width: 1200px;
            margin: 0 auto;
            padding: 60px 25px 25px;

            @include media-queries($from: md) {
                padding: 120px 20px;
            }
        }

        h2 {
            padding-bottom: 20px;
            font-size: 36px;

            @include media-queries($from: md) {
                font-size: 70px;
            }
        }

        [type="text"],
        [type="tel"],
        [type="email"],
        textarea {
            display: block;
            width: 100%;
            height: 40px;
            padding: 9px 0;
            border-radius: 0;
            border: 0;
            border-bottom: 1px solid #fff;
            background: #000;
            color: #fff;
            font-size: 16px;
            line-height: 20px;
            box-sizing: border-box;
            appearance: none;
            box-sizing: border-box;

            @include media-queries($from: md) {
                border-bottom: 2px solid #fff;
            }
        }

        textarea {
            height: auto;
            border-bottom: 0;
        }

        table {
            width: 100%;
            border-collapse: collapse;
        }

        td {
            padding-top: 30px;
            box-sizing: border-box;

            @include media-queries($to: md) {
                display: block;
            }

            @include media-queries($from: md) {
                &:nth-child(1) {
                    padding-right: percentage(100 / 1160);
                }
                &:nth-child(2) {
                    padding-left: percentage(100 / 1160);
                }
                &[colspan="2"] {
                    padding-right: 0;
                }
            }

            label {
                display: block;

                > span {
                    display: block;
                    font-size: 16px;
                    font-weight: $font-weight-bold;
                    line-height: 1.5;

                    @include media-queries($from: md) {
                        font-size: 18px;
                    }
                }
            }
        }

        .content {
            padding-top: 50px;

            label {
                padding: 10px;
                border: 1px solid #fff;
            }
        }

        .agree {
            padding-top: 30px;
            text-align: center;

            h3 {
                padding-bottom: 15px;
                color: #8b8b8b;
                font-weight: $font-weight-medium;
                font-size: 14px;
                text-align: left;

                @include media-queries($from: md) {
                    padding-bottom: 25px;
                    font-size: 20px;
                }
            }

            div {
                overflow: hidden;
                overflow-y: auto;
                height: 80px;
                color: #666;
                font-weight: $font-weight-light;
                font-size: 12px;
                line-height: 1.5;
                text-align: left;

                @include media-queries($from: md) {
                    font-size: 16px;
                }
            }

            label {
                display: inline-block;
                position: relative;
                margin-top: 20px;
                vertical-align: top;

                input {
                    @include visually-hidden;
                }

                span {
                    display: inline-block;
                    position: relative;
                    padding-left: 25px;
                    color: #666;
                    font-weight: $font-weight-light;
                    font-size: 12px;
                    line-height: 16px;
                    vertical-align: top;

                    @include media-queries($from: md) {
                        font-size: 20px;
                        line-height: 24px;
                    }

                    &:before,
                    &:after {
                        position: absolute;
                        content: "";
                    }

                    &:before {
                        top: 0;
                        left: 0;
                        width: 14px;
                        height: 14px;
                        border: 1px solid #6d6d6d;

                        @include media-queries($from: md) {
                            top: 4px;
                        }
                    }

                    &:after {
                        top: 4px;
                        left: 4px;
                        width: 8px;
                        height: 8px;
                        background: transparent;
                        transition: background .4s ease;

                        @include media-queries($from: md) {
                            top: 8px;
                        }
                    }
                }

                input:checked + span:after {
                    background: #6d6d6d;
                }
            }
        }

        [type="submit"] {
            display: block;
            width: 100%;
            height: 50px;
            margin-top: 20px;
            border-radius: 0;
            border: 0;
            background: #fff;
            color: #000;
            font-weight: $font-weight-bold;
            font-size: 18px;
            line-height: 50px;

            @include media-queries($from: md) {
                margin-top: 60px;
                font-size: 20px;
            }
        }

        .close {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 40px;
            height: 40px;
            @include media-queries($from: md) {
                top: 30px;
                right: 30px;
            }

            &:before,
            &:after {
                position: absolute;
                top: calc(50% - 1px);
                left: calc(50% - 15px);
                width: 30px;
                height: 2px;
                background: #fff;
                content: "";
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }

            span {
                @include visually-hidden;
            }
        }
    }

    .loading {
        display: none;
        align-items: center;
        justify-content: center;
        position: fixed;
        top:0;
        left:0;
        z-index: 1000;
        width: 100%;
        height: 100%;

        &.on {
            display: flex;
        }

        &:before {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.2);
            content:"";
        }

        .loading-container,
        .content {
            position: relative;
            z-index: 10;
            width: 100px;
            height: 100px;
            border-radius: 100%;
        }

        .content {
            border: 2px solid transparent;
            border-color: transparent #fff transparent #FFF;
            animation: rotate-loading 1.5s linear 0s infinite normal;
            transform-origin: 50% 50%;
        }

        .loading-container .content {
            transition: all 0.5s ease-in-out;
        }

        .loading-text {
            animation: loading-text-opacity 2s linear 0s infinite normal;
            color: #fff;
            font-family: $font-language-en;
            font-size: 10px;
            font-weight: bold;
            margin-top: 45px;
            opacity: 0;
            position: absolute;
            text-align: center;
            text-transform: uppercase;
            top: 0;
            width: 100px;
        }

        @keyframes rotate-loading {
            0%  {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        @keyframes rotate-loading {
            0%  {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        @keyframes loading-text-opacity {
            0%  {
                opacity: 0;
            }
            20% {
                opacity: 0;
            }
            50% {
                opacity: 1;
            }
            100%{
                opacity: 0;
            }
        }
    }
}