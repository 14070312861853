@import "~swiper/swiper-bundle.css";
@import "~aos/dist/aos.css";

.swiper-pagination {
    line-height: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
}

.swiper-pagination-bullet-active {
    background: #797979;
}

.swiper-button-prev,
.swiper-button-next {
    width: 30px;
    height: 24px;
    margin-top: -12px;

    &:before,
    &:after {
        position: absolute;
        content: "";
    }

    &:before {
        top: calc(50% - 8px);
        width: 16px;
        height: 16px;
        transform-origin: center;
    }

    &:after {
        top: calc(50% - 1px);
        left: 0;
        width: 100%;
        height: 3px;
        background: #000;
    }

    span {
        @include visually-hidden;
    }
}

.swiper-button-prev {
    &:before {
        left: 0;
        box-shadow: inset 3px -3px #000;
        transform: rotate(45deg);
    }
}
.swiper-button-next {
    &:before {
        right: 0;
        box-shadow: inset -3px -3px #000;
        transform: rotate(-45deg);
    }
}

.open-modal {
    overflow: hidden;
}

.centered-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.main-title {
    padding: 75px 0 38px 0;

    strong,
    span {
        display: block;
        line-height: 1;
    }

    strong {
        color: #104aa1;
        font-weight: $font-weight-regular;
        font-size: 22px;
    }

    span {
        margin-top: 8px;
        color: #1f1f1f;
        font-family: $font-language-en;
        font-weight: $font-weight-extrabold;
        font-size: 48px;
        letter-spacing: 0;
    }

    @include media-queries($from: xl) {
        padding: 166px 0 40px 0;

        strong {
            font-size: 40px;
        }

        span {
            margin-top: 3px;
            font-size: 70px;
        }
    }
}

.main-description {
    @include clearfix;

    br {
        @include media-queries($to: md) {
            display: none;
        }
    }

    strong {
        color: #104aa1;
        font-weight: $font-weight-bold;
    }

    .title {
        font-size: 24px;
        line-height: (64 / 44);
        @include media-queries($from: md) {
            float: left;
            max-width: 50%;
        }
        @include media-queries($from: lg) {
            font-size: 44px;
        }

        strong {
            @include media-queries($from: md) {
                display: block;
            }
        }
    }

    p {
        padding-top: 50px;
        font-weight: $font-weight-light;
        font-size: 18px;

        line-height: (40 / 24);
        @include media-queries($from: md) {
            float: right;
            max-width: 50%;
            padding-top: 0;
        }
        @include media-queries($from: lg) {
            font-size: 24px;
        }

        strong {
            display: block;
        }
    }
}

.logo {
    background: {
        repeat: no-repeat;
        position: center;
        size: contain;
    };

    &:before {
        display: block;
        content: "";
    }

    span {
        @include visually-hidden;
    }

    &.recobell,
    &.recobell-white,
    &.recobell-gray {
        &:before {
            padding-top: percentage(32 / 175);
        }
    }

    &.recobell {
        background-image: url("/assets/images/h_logo_2.png");
    }

    &.recobell-white,
    &.recobell-gray {
        background-image: url("/assets/images/h_logo.png");
    }

    &.recobell-gray {
        opacity: .2;
    }

    &.fsn {
        background-image: url("/assets/images/logo_fsn.png");

        &:before {
            padding-top: percentage(44 / 102);
        }
    }

    &.cauly {
        background-image: url("/assets/images/logo_cauly.png");

        &:before {
            padding-top: percentage(29 / 85);
        }
    }

    &.behave {
        background-image: url("/assets/images/logo_behave.png");

        &:before {
            padding-top: percentage(44 / 96);
        }
    }

    &.nmg {
        background-image: url("/assets/images/logo_nmg.png");

        &:before {
            padding-top: percentage(71 / 141);
        }
    }

    &.inbuzz {
        background-image: url("/assets/images/logo_inbuzz.png");

        &:before {
            padding-top: percentage(45 / 120);
        }
    }

    &.e-motion {
        background-image: url("/assets/images/logo_e_motion.png");

        &:before {
            padding-top: percentage(21 / 115);
        }
    }

    &.idd {
        background-image: url("/assets/images/logo_idd.png");

        &:before {
            padding-top: percentage(42 / 75);
        }
    }
}

.footer {
    padding: 30px 0;
    background: #171717;
    text-align: center;
    @include media-queries($from: md) {
        padding: 60px 0;
    }

    div {
        max-width: 1160px;
        margin: 0 auto;
        padding: 0 20px;
        @include clearfix;
    }

    h1 {
        display: inline-block;
        width: 100px;
        vertical-align: top;
        @include media-queries($from: md) {
            float: left;
            width: 175px;
            opacity: .2;
        }
    }

    p {
        padding-top: 10px;
        color: #fff;
        font-size: 10px;
        opacity: .2;

        @include media-queries($from: md) {
            float: right;
            font-size: 16px;
        }
    }
}