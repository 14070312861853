.about {
    .list {
        margin-top: 60px;

        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f9f9f9;
            height: (576px / 2);
        }

        .swiper-pagination {
            position: relative;
            width: 100%;
            margin-top: 20px;
            text-align: center;
        }

        .swiper-pagination-bullet {
            width: 7px;
            height: 7px;
            margin: 0 5px;
            background: #e7e6ec;
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            background: #797979;
        }

        @each $index, $size in (1, 103), (2, 113), (3, 115), (4, 93) {
            .content-#{$index} {
                figure span {
                    height: #{$size / 2}px;
                }
            }
        }

        figure {
            span,
            figcaption {
                display: block;
            }

            span {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }

            figcaption {
                margin-top: 30px;
                font-weight: 700;
                font-size: 20px;
                line-height: 1;
            }
        }

        figcaption,
        p {
            display: block;
            color: #000;
            text-align: center;
        }

        p {
            margin-top: 15px;
            font-weight: 300;
            font-size: 14px;
            line-height: (40 / 28);
        }
    }

    .signboard {
        padding-top: 75px;

        > div {
            position: relative;
        }

        p {
            width: calc(50% + 96px);
            height: 200px;

            span:nth-child(1) {
                top: -32px;
                right: (372px / 2);
            }

            span:nth-child(2) {
                top: 23px;
                right: -54px;
            }

            span:nth-child(3) {
                top: 77px;
                right: 32px;
            }

            span:nth-child(4) {
                top: 130px;
                right: 50px;
            }
        }

        p:first-child {
            position: relative;
            z-index:10;
            overflow: hidden;
            background: url(/assets/images/bg_about.jpg) no-repeat right top / auto 100%;
        }

        p:last-child {
            position: absolute;
            top:0;
            left:0;

            span {
                color: #104aa1;
            }
        }

        span {
            display: block;
            position: absolute;
            color: #fff;
            font-family: $font-language-en;
            font-weight: 500;
            font-size: 39px;
            transform: translateY(50px);
            transition-property: transform,top;
            transition-duration: .3s, .3s;
            transition-timing-function: cubic-bezier(.215,.61,.355,1),cubic-bezier(.215,.61,.355,1);

            &.ani {
                transform: translateY(0);
            }
        }
    }

    @include media-queries($from: md) {
        .list {
            .swiper-wrapper {
                display: block;
            }

            .swiper-slide {
                display: block;
                float: left;
                width: 50% !important;
                height: 230px;
                margin-top: 40px;
                background: #fff;
                flex-shrink: 1;
            }

            .swiper-pagination {
                opacity:0;
            }

            @each $index, $size in (1, 103), (2, 113), (3, 115), (4, 93) {
                .content-#{$index} {
                    figure span {
                        height: #{$size / 1.5}px;
                    }
                }
            }

            figure {
                figcaption {
                    font-size: 22px;
                }
            }

            p {
                margin-top: 15px;
                font-weight: 300;
                font-size: 16px;
                line-height: (40 / 28);
            }
        }

        .signboard {
            padding-top: 100px;

            > div {
                position: relative;
            }

            p {
                width: calc(50% + 150px);
                height: 350px;

                span:nth-child(1) {
                    top: -47px;
                    right: 231px;
                }

                span:nth-child(2) {
                    top: 42px;
                    right: -115px;
                }

                span:nth-child(3) {
                    top: 130px;
                    right: 18px;
                }

                span:nth-child(4) {
                    top: 216px;
                    right: 54px;
                }
            }

            span {
                font-size: 60px;
            }
        }
    }

    @include media-queries($from: xl) {
        .text {
            font-size: 24px;
            line-height: (40 / 24);

            @include clearfix;

            strong {
                float: left;
                font-size: 44px;
                line-height: (64 / 44);
            }

            > span {
                float: right;
                margin: 0;
            }
        }

        .list {
            margin-top: 170px;

            .swiper-wrapper {
                display: flex;
                justify-content: center;
            }

            .swiper-slide {
                display: block;
                width: 268px !important;
                height: 369px;
                margin-top: 0;
                padding-top: 56px;
                background: #fff;
                box-sizing: border-box;
                transition: background .2s ease;

                &.on,
                &:hover {
                    background: #f9f9f9;

                    figure {
                        top: 204px;
                    }

                    p {
                        transform: translateY(0);
                        opacity: 1;
                        transition: transform .4s .2s ease, opacity .3s .2s ease;
                    }
                }
            }

            .swiper-pagination {
                visibility: hidden;
            }

            .swiper-pagination-bullet-active {
                background: #fff;
            }

            @each $index, $size, $marginTop, $top in (1, 103,71, 71), (2, 113, 68, 65), (3, 115, 65, 66), (4, 93, 76, 76) {
                .content-#{$index} {
                    figure {
                        top: #{$top}px;

                        span {
                            height: #{$size}px;
                        }

                        figcaption {
                            margin-top: #{$marginTop}px;
                        }
                    }
                }
            }

            figure {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                transition: top .3s ease;

                span,
                figcaption {
                    display: block;
                }

                figcaption {
                    font-size: 24px;
                }
            }

            figcaption,
            p {
                display: block;
                color: #000;
                text-align: center;
            }

            p {
                margin-top: 0;
                font-size: 18px;
                line-height: (27 / 18);
                transform: translateY(80px);
                opacity: 0;
            }
        }

        .signboard {
            padding-top: 164px;

            > div {
                position: relative;
            }

            p {
                width: calc(50% + 294px);
                height: 524px;

                span:nth-child(1) {
                    top: -95px;
                    right: 505px;
                }

                span:nth-child(2) {
                    top: (90px - 42);
                    right: -230px;
                }

                span:nth-child(3) {
                    top: (229px - 44);
                    right: 18px;
                }

                span:nth-child(4) {
                    top: (382px - 72);
                    right: 78px;
                }
            }

            span {
                font-size: 120px;
                letter-spacing: 1px;
            }
        }
    }
}