.solution {
    margin-top: 75px;

    .text {
        color: #000;
        font-weight: 300;
        font-size: 19px;
        line-height: (56 / 38);

        > strong,
        > span {
            display: block;

            > span {
                color: #104aa1;
            }
        }

        strong,
        > span span {
            font-weight: 700;
        }

        strong {
            color: #000;
            font-size: 25px;
            line-height: (72 / 50);
        }

        > span {
            margin-top: 50px;
        }

        br {
            display: none;
        }
    }

    .content {
        padding: 80px 0;
        background: #f8f8f8;

        & + .content {
            background: #104aa1;
        }
    }

    .text {
        span {
            margin: 0;
            text-align: center;
            line-height: (56 / 38);
        }
    }

    .box {
        position: relative;
        margin-top: 48px;

        p {
            position: absolute;
            top: 50%;
            left:0;
            width: 100%;
            color: #1f1f1f;
            font-weight: 700;
            font-size: 32px;
            text-align: center;
            transform: translateY(-50%);

            span {
                font-family: $font-language-en;
            }
        }
    }

    .circle {
        position: relative;
        width: percentage(372 / 750);
        max-width: 469px;
        margin: 0 auto;
        border: 1px solid #3163ad;
        border-radius: 100%;

        &:before {
            display: block;
            padding-top: 100%;
            content: "";
        }

        &-content {
            position: absolute;
            top: 50%;
            left: 50%;
            width: percentage(194 / 372);
            border: 1px solid #3163ad;
            border-radius: 100%;
            animation: circleTurn 6s infinite ease-in-out;

            &:before,
            &:after {
                content: "";
            }

            &:before {
                display: block;
                padding-top: 100%;
            }

            &:after {
                position: absolute;
                top: percentage(31 / 194);
                left:-5%;
                width: 110%;
                height: percentage(126 / 194);
                background: #f8f8f8;
            }

            @keyframes circleTurn {
                0% {
                    transform: translate(-50%, -50%) rotate(0);
                }

                35% {
                    transform: translate(-50%, -50%) rotate(1080deg);
                }

                50% {
                    transform: translate(-50%, -50%) rotate(1080deg);
                }

                85% {
                    transform: translate(-50%, -50%) rotate(2160deg);
                }

                100% {
                    transform: translate(-50%, -50%) rotate(2160deg);
                }
            }
        }

        .bullet {
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background: #104aa1;

            @each $index, $top, $left in (2, 93, 28), (3, 86, 325), (4, 324, 275) {
                &:nth-child(#{$index}) {
                    top: percentage($top / 372);
                    left: percentage($left / 372);
                }
            }
        }
    }

    .steps {
        margin-top: 38px;
        padding-bottom: 46px;
        background: url(/assets/images/icon_business_arrow.jpg) no-repeat center bottom / 14px auto;
        text-align: center;

        div + div {
            margin-top: 28px;
        }

        dt {
            color: #104aa1;
            font-family: $font-language-en;
            font-weight: 600;
            font-size: 18px;
        }

        dd {
            margin-top: 14px;
            color: #333333;
            font-weight: 300;
            font-size: 14px;
            line-height: (40 / 28);
        }

        br {
            display: none;
        }
    }

    .result {
        margin-top: 22px;
        color: #104aa1;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
    }

    .text2 {
        color: #ffffff;
        font-weight: 300;
        font-size: 20px;
        line-height: (56 / 40);
        text-align: center;

        strong {
            color: #8adfff;
        }

        br {
            display: none;
        }
    }

    .swiper-container {
        margin-top: 40px;
    }

    .swiper-slide {
        p {
            color: #bbc5dd;
            font-weight: 300;
            font-size: 15px;
            text-align: center;
            line-height: (46 / 30);

            @each $index, $height in (1, 80), (2, 84), (3, 71) {
                .text-#{$index} {
                    background-image: url(/assets/images/icon_business_#{$index}.png);
                    background-size: #{$height / 2}px;
                }
            }

            > strong,
            > span {
                display: block;
            }

            > strong {
                display: block;
                padding-top: 75px;
                background-repeat: no-repeat;
                background-position: center top;
                color: #fff;
                font-weight: 500;
                font-size: 17px;
            }

            > span {
                padding-top: 15px;

                span {
                    display: block;
                }
            }

            br {
                display: none;
            }
        }
    }

    .swiper-pagination {
        position: relative;
        width: 100%;
        margin-top: 20px;
        text-align: center;
    }

    .swiper-pagination-bullet {
        width: 7px;
        height: 7px;
        margin: 0 5px;
        background: #0b3a81;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        background: #ffffff;
    }

    @include media-queries($from: md) {
        .text {
            font-size: 18px;
            line-height: (40 / 24);

            @include clearfix;

            strong {
                float: left;
                font-size: 24px;
                line-height: (64 / 44);
            }

            > span {
                margin: 0;
            }

            br {
                display: block;
            }
        }

        .box {
            p {
                font-size: 64px;
            }
        }

        .circle {
            .bullet {
                width: 10px;
                height: 10px;
            }
        }

        .steps {
            margin-top: 50px;
            padding-bottom: 60px;
            background: url(/assets/images/icon_business_arrow.jpg) no-repeat center bottom / 20px auto;

            dt {
                font-size: 20px;
            }

            dd {
                font-size: 16px;
            }
        }

        .result {
            font-size: 20px;
        }

        .text2 {
            font-size: 22px;

            br {
                display: block;
            }
        }

        .swiper-slide {
            width: 33.33%;
            padding: 0 15px;
            box-sizing: border-box;

            p {
                font-size: 18px;
                text-align: center;

                > strong {
                    font-size: 20px;
                }
            }
        }

        .swiper-pagination {
            opacity: 0;
        }
    }

    @include media-queries($from: xl) {
        .content {
            padding: 100px 0;
        }

        .text {
            font-size: 24px;
            line-height: (40 / 24);

            @include clearfix;

            strong {
                float: left;
                font-size: 44px;
                line-height: (64 / 44);
            }

            > span {
                float: right;
                margin: 0;
            }
        }

        .text {
            span {
                float: none;
            }
        }

        .container {
            position: relative;
            margin-top: 40px;
        }

        .box {
            margin: 0;

            p {
                font-size: 82px;
            }
        }

        .circle {
            .bullet {
                width: 17px;
                height: 17px;
            }
        }

        .steps {
            padding-bottom: 92px;
            background: url(/assets/images/icon_business_arrow.jpg) no-repeat center bottom / 34px auto;

            div {
                position: absolute;
                left: 50%;
                text-align: left;

                @each $index, $top, $left, $textAlign in (1, 70, -474, right), (2, 70, 244), (3, 448, 140) {
                    &:nth-child(#{$index}) {
                        top: #{$top}px;
                        margin-left: #{$left}px;

                        @if ($textAlign) {
                            text-align: #{$textAlign};
                        }
                    }
                }
            }

            div + div {
                margin: 0;
            }

            dt {
                font-size: 24px;
            }

            dd {
                font-size: 18px;
            }

            br {
                display: block;
            }
        }

        .result {
            font-size: 26px;
        }

        .text2 {
            font-size: 26px;

            br {
                display: block;
            }
        }

        .swiper-container {
            margin-top: 80px;
        }

        .swiper-wrapper {
            display: flex;
            justify-content: center;
            text-align: center;
        }

        .swiper-slide {
            display: inline-block;
            width: auto;
            padding: 0 60px;
            box-sizing: border-box;

            p {
                font-size: 18px;
                text-align: left;

                > strong {
                    display: inline-block;
                    padding: 0 80px 0 0;
                    background-position: right center;
                    font-size: 24px;
                    line-height: (38 / 24);
                }

                > span {
                    padding-top: 30px;

                    span {
                        position: relative;
                        padding-left: 11px;

                        &:before {
                            position: absolute;
                            top: 10px;
                            left: 0;
                            width: 3px;
                            height: 3px;
                            border-radius: 100%;
                            background: rgba(255,255,255,0.2);
                            content: "";
                        }
                    }
                }

                br {
                    display: block;
                }
            }
        }

        .swiper-pagination {
            opacity: 0;
        }
    }
}