html {
    font-family: sans-serif;
}

body,
button,
code,
dd,
dl,
dt,
fieldset,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
legend,
li,
ol,
p,
pre,
table,
td,
th,
ul {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

ol,
ul {
    list-style: none;
}

address {
    font-style: normal;
}

hr {
    display: none;
}

abbr {
    cursor: help;
}

[role=button],
button {
    cursor: pointer;
    border: 0;
    background: transparent;

}

html,
body {
    min-height: 100%;
}

fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0;
}

legend {
    border: 0;
    background: transparent;
    color: transparent;
    font: 0/0 a;
    text-shadow: none;
}

@import "~normalize.css/normalize.css";

@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("NotoSansKr"), local("Noto Sans Kr");
    src: url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2") format("woff2"), url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff") format("woff");
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("NotoSansKr"), local("Noto Sans Kr");
    src: url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2") format("woff2"), url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff") format("woff");
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("NotoSansKr"), local("Noto Sans Kr");
    src: url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2") format("woff2"), url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff") format("woff");
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("NotoSansKr"), local("Noto Sans Kr");
    src: url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2") format("woff2"), url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff") format("woff");
    text-rendering: optimizeLegibility;
}


@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Montserrat");
    src: url("/assets/fonts/Montserrat-Regular.woff2") format("woff2"), url("/assets/fonts/Montserrat-Regular.woff") format("woff");
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Montserrat");
    src: url("/assets/fonts/Montserrat-Medium.woff2") format("woff2"), url("/assets/fonts/Montserrat-Medium.woff") format("woff");
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Montserrat");
    src: url("/assets/fonts/Montserrat-Bold.woff2") format("woff2"), url("/assets/fonts/Montserrat-Bold.woff") format("woff");
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local("Montserrat");
    src: url("/assets/fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("/assets/fonts/Montserrat-ExtraBold.woff") format("woff");
    text-rendering: optimizeLegibility;
}

$font-weight-thin: 100;
$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

$font-language-ko: quote(Noto Sans KR), quote(Malgun Gothic), quote(맑은고딕), helvetica, sans-serif;
$font-language-en: Montserrat, Lato, helvetica, quote(Apple SD Gothic Neo), sans-serif;

:lang(ko) {
    font-family: $font-language-ko;
    letter-spacing: -.025em;
    word-break: keep-all;
}

:lang(en) {
    font-family: $font-language-en;
}

body {
    line-height: 1.5;
}