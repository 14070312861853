@mixin clearfix {
    &::after {
        display: block;
        clear: both;
        content: "";
    }
}

@mixin visually-hidden {
    overflow: hidden !important;
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    border: 0 !important;
    white-space: nowrap !important;
    clip: rect(0, 0, 0, 0) !important;
    clip-path: inset(0 0 99.9% 99.9%) !important;
}

@function first-index($string, $direction: 'left') {
    @for $i from 1 through str-length($string) {
        $index: if($direction == 'left', $i, -$i);

        @if str-slice($string, $index, $index) != ' ' {
            @return $index;
        }
    }

    @return 0;
}

@function trim($string) {
    @return str-slice(
                    $string,
                    first-index($string, 'left'),
                    first-index($string, 'right')
    );
}

@function is-px($value) {
    @return (type-of($value) == 'number') and (str-index(inspect($value), px) != null);
}

$media-queries-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
) !default;

@mixin media-queries($args...) {
    $args: keywords($args);
    $media-type: screen;
    $from: null;
    $has-from: false;
    $to: null;
    $has-to: false;
    $expression: null;
    $breakpoints: $media-queries-breakpoints;
    $queries: '';

    @if map-has-key($args, 'media-type') {
        $media-type: map-get($args, 'media-type');
    }

    @if map-has-key($args, 'expression') {
        $expression: map-get($args, 'expression');
    }

    @if map-has-key($args, 'breakpoints') {
        $breakpoints: map-merge($breakpoints, map-get($args, 'breakpoints'));
    }

    @if map-has-key($args, 'from') {
        $from: map-get($args, 'from');

        @if map-has-key($breakpoints, $from) {
            $from: map-get($breakpoints, $from);
            $has-from: true;
        }
    }

    @if map-has-key($args, 'to') {
        $to: map-get($args, 'to');

        @if map-has-key($breakpoints, $to) {
            $to: map-get($breakpoints, $to);
            $has-to: true;
        }
    }

    $queries: $media-type;

    @if $from {
        @if $has-from and $has-to and is-px($from) {
            $from: $from + 1;
        }

        $queries: append($queries, "and (min-width: #{$from})", space);
    }

    @if $to {
        $queries: append($queries, "and (max-width: #{$to})", space);
    }

    @if $expression {
        @each $key, $value in $expression {
            @if $key == media-type {
                $queries: append($queries, ", #{$value}", space);
            } @else if $key == from {
                $expression-from: $value;

                @if map-has-key($breakpoints, $expression-from) {
                    $expression-from: map-get($breakpoints, $expression-from);
                }

                $queries: append($queries, "and (min-width: #{$expression-from})", space);
            } @else if $key == to {
                $expression-to: $value;

                @if map-has-key($breakpoints, $expression-to) {
                    $expression-to: map-get($breakpoints, $expression-to);
                }

                $queries: append($queries, "and (max-width: #{$expression-to})", space);
            } @else {
                $queries: append($queries, "and (#{$key}: #{$value})", space);
            }
        }
    }

    @media #{$queries} {
        @content;
    }
}