.visual {
    position: relative;
    background: #e2e2e2;

    @at-root .open-visual {
        //overflow: hidden;
        //overflow-y: scroll;
        //height: 100vh;
    }

    @at-root .open-visual & {
        position: relative;
        z-index: 10000;
    }

    .inner {
        overflow: hidden;
        position: relative;
        height: 100vh;
        //min-height: 240px;
        min-height: 460px;

    }

    .backdrop,
    .backdrop span,
    .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .backdrop {
        overflow: hidden;

        span {
            background: {
                repeat: no-repeat;
                position: center;
                size: cover;
            };
            transform-origin: center;
            //transform: scale(1);
            //opacity: 0.8;
            transform: scale(1.1);
        }

        &.fade span {
            opacity: 1;
            transform: scale(1);
            transition: all .4s linear;
        }

        &.in span {
            transform: scale(1.2);
            transition: transform .8s linear;
        }
    }

    .char {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40%;
        min-width: 180px;
        max-width: 356px;
        margin: 0 auto;
        transform-origin: center;
        transform: translate(-50%, -50%);

        &:before {
            display: block;
            padding-top: 100%;
            content: "";
        }

        .item {
            position: absolute;
            transform-origin: center;
            opacity: 0;
            max-height: 100%;

            &.r {
                top: percentage(32 / 356);
                left: percentage(88 / 356);
                width: percentage(198.5 / 356);
                height: percentage(260.5 / 356);
                transform: translate3d(-10px, 10px, 0);
            }

            &.b {
                top: percentage(55 / 356);
                left: percentage(67 / 356);
                width: percentage(200 / 356);
                height: percentage(262 / 356);
                transform: translate3d(10px, -10px, 0);
            }

            &.box {
                top: 0;
                left: 0;
                width: percentage(356 / 356);
                height: percentage(356 / 356);
                transform: scale(.9);
            }

            &.logo {
                top: 100%;
                left: 0;
                width: 100%;
                height: percentage(53 / 356);
                background: url("/assets/images/h_logo.png") no-repeat center/auto percentage(16 / 53);
            }
        }

        &.in .item {
            opacity: 1;

            &.r {
                transform: translate3d(0, 0, 0);
                transition: all .3s ease .1s;
            }

            &.b {
                transform: translate3d(0, 0, 0);
                transition: all .3s ease .2s;
            }

            &.box {
                transform: scale(1);
                transition: all .4s linear;
            }

            &.logo {
                transition: all .4s ease .5s;
            }
        }

        &.out {
            opacity: 0;
            transform: translate(-50%, -50%) scale(.9);
            transition: .4s ease;
        }
    }

    .title {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        color: #fff;
        font-weight: $font-weight-light;
        font-size: 28px;
        line-height: (80 / 60);
        text-align: center;
        text-shadow: 2px 2px 6px rgba(#000, .2);
        transform: translateY(-50%);

        span,
        strong {
            display: block;
            transform: translateY(30%);
            opacity: 0;
        }

        strong {
            font-weight: $font-weight-bold;
        }

        @include media-queries($from: lg) {
            font-size: 60px;
        }

        &.in {
            span,
            strong {
                transform: translateY(0);
                opacity: 1;
                transition: .6s ease .5s;
            }

            span + span {
                transition-delay: .6s;
            }

            strong {
                transition-delay: .7s;
            }
        }
    }
}